import { createSlice } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit'

import UserService from 'services/UserService'


export const initialState = {
    userList: {
        data: [],
        isLoading: false,
        error: false,
    },
    userListDynamic: {
        data: [],
        isLoading: false,
        error: false,
    },
    userDetail: {
        data: [],
        isLoading: false,
        error: false,
    },
    userAdd: {
        data: [],
        isLoading: false,
        error: false,
        success: null
    },
    userEdit: {
        data: [],
        isLoading: false,
        error: false,
        success: null
    },
}

export const fetchUserList = createAsyncThunk('user/getUserList', async (data, { rejectWithValue }) => {
    try {
        const response = await UserService.getUserList()
        return [...response.data]
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})

export const fetchUserListDynamic = createAsyncThunk('user/getUserListDynamic', async (data, { rejectWithValue }) => {
    try {
        const response = await UserService.getUserListDynamic(data)
        return response.data
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})

export const fetchUsersToExcel = createAsyncThunk(
    'user/getUsersExcel',
    async (data, { rejectWithValue }) => {
        try {
            const response = await UserService.getUsersToExcel(data)
            return response
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error')
        }
    }
)


export const fetchUserDetail = createAsyncThunk('user/getUserDetail', async (data, { rejectWithValue }) => {
    try {
        const response = await UserService.getUserDetail(data)
        return response.data
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})

export const addUser = createAsyncThunk('user/addUser', async (data, { rejectWithValue }) => {
    try {
        const response = await UserService.addUser(data)
        return [response]
    } catch (err) {
        return rejectWithValue(err.response?.error || 'Error')
    }
})


export const deleteUser = createAsyncThunk('user/deleteUser', async (params, { rejectWithValue }) => {
    try {
        await UserService.deleteUser(params)
        return params
    } catch (err) {
        return rejectWithValue(err.response?.data?.error || 'Error')
    }
})

export const updateUser = createAsyncThunk('user/updateUser', async(data, {rejectWithValue }) => {
    try {
        const response = await UserService.updateUser(data)
        return response
    } catch (err) {
        return rejectWithValue(err.response?.data?.error || 'Error')
    }
})

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        updateAddUserStatus(state, action) {
            state.addUserStatus = action.payload
        },
    },
    extraReducers(builder) {
        builder
            // List Users
            .addCase(fetchUserList.pending, (state, action) => {
                state.userList.isLoading = true
            })
            .addCase(fetchUserList.rejected, (state, action) => {
                state.userList.isLoading = false
                state.userList.error = true
            })
            .addCase(fetchUserList.fulfilled, (state, action) => {
                state.userList.isLoading = false
                state.userList.error = false
                state.userList.data = action.payload
            })

            .addCase(fetchUserListDynamic.pending, (state, action) => {
                state.userListDynamic.isLoading = true
            })
            .addCase(fetchUserListDynamic.rejected, (state, action) => {
                state.userListDynamic.isLoading = false
                state.userListDynamic.error = true
            })
            .addCase(fetchUserListDynamic.fulfilled, (state, action) => {
                state.userListDynamic.isLoading = false
                state.userListDynamic.error = false
                state.userListDynamic.data = action.payload
            })

            // Get User by ID
            .addCase(fetchUserDetail.pending, (state, action) => {
                state.userDetail.isLoading = true
            })
            .addCase(fetchUserDetail.rejected, (state, action) => {
                state.userDetail.isLoading = false
                state.userDetail.error = true
            })
            .addCase(fetchUserDetail.fulfilled, (state, action) => {
                state.userDetail.isLoading = false
                state.userDetail.error = false
                state.userDetail.data = action.payload
            })

            // Add User
            .addCase(addUser.pending, (state, action) => {
                state.userAdd.isLoading = true
            })
            .addCase(addUser.rejected, (state, action) => {
                state.userAdd.isLoading = false
                state.userAdd.error = true
            })
            .addCase(addUser.fulfilled, (state, action) => {
                state.userAdd.isLoading = false
                state.userAdd.error = false
                state.userAdd.success = true
                state.userAdd.data = [...state.userList.data, action.payload]
            })

            .addCase(deleteUser.fulfilled, (state, action) => {
                state.userList.data = state.userList.data.filter((user) => user.id !== action.payload);
            })


            // Edit user
            .addCase(updateUser.pending, (state, action) => {
                state.userEdit.isLoading = true
            })
            .addCase(updateUser.rejected, (state,action) => {
                state.userEdit.isLoading = false
                state.userEdit.error = action.error.message
            })
            .addCase(updateUser.fulfilled, (state, action) => {
                state.userEdit.isLoading = false
                state.userEdit.error = false
                state.userEdit.success = true

                // state.userList.data = state.userList.map(user=>{
                //     if (action.payload.id === user.id) {
                //         user.name = action.payload.name
                //         user.surname = action.payload.surname
                //         user.role = action.payload.role
                //         user.email = action.payload.email
                //         user.phone = action.payload.phone
                //         user.status = action.payload.status
                //         user.operatorId = action.payload.operatorId
                //     }
                //     return user;
                // })
            })

            
    },
})

export const getUserList = (state) => state.user.userList
export const getUserListStatus = (state) => state.user.getUserListStatus
export const getUserListError = (state) => state.user.getUserListError

export const addUserStatus = (state) => state.user.addUserStatus
export const addUserError = (state) => state.user.addUserError

export const { updateAddUserStatus } = userSlice.actions

export default userSlice.reducer
