import { createSlice } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit'
import TransactionService from 'services/TransactionService'

export const initialState = {
    chargingSessionList: {
        data: [],
        isLoading: false,
        error: false,
    },

    activeChargingSessionList: {
        data: [],
        isLoading: false,
        error: false,
    },

    paymentsList: {
        data: [],
        isLoading: false,
        error: false,
    },

    chargeback: {
        data: [],
        isLoading: false,
        error: false,
    },
}

export const fetchChargingSessions = createAsyncThunk(
    'transaction/chargingSession',
    async (data, { rejectWithValue }) => {
        try {
            const response = await TransactionService.getChargingSessions(data)
            console.log(data)
            return response.data
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error')
        }
    }
)

export const fetchChargingSessionsToExcel = createAsyncThunk(
    'transaction/getTransactionsExcel',
    async (data, { rejectWithValue }) => {
        try {
            const response = await TransactionService.getChargingSessionsToExcel(data)
            return response
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error')
        }
    }
)

export const fetchActiveChargingSessionsToExcel = createAsyncThunk(
    'transaction/getActiveTransactionsExcel',
    async (data, { rejectWithValue }) => {
        try {
            const response = await TransactionService.getActiveChargingSessionsToExcel(data)
            return response
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error')
        }
    }
)

export const fetchActiveChargingSessions = createAsyncThunk(
    'transaction/activeChargingSession',
    async (data, { rejectWithValue }) => {
        try {
            const response = await TransactionService.getActiveChargingSessions(data)
            return response.data
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error')
        }
    }
)

export const fetchPaymentsList = createAsyncThunk('transaction/payments', async (data, { rejectWithValue }) => {
    try {
        const response = await TransactionService.getPaymentsList(data)
        console.log(response.data)
        return response.data
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})

export const chargeback = createAsyncThunk('transaction/chargeback', async (data, { rejectWithValue }) => {
    try {
        const response = await TransactionService.chargeback(data)
        console.log(response.data)
        return response.data
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})

export const transactionSlice = createSlice({
    name: 'transaction',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchChargingSessions.pending, (state, action) => {
                state.chargingSessionList.isLoading = true
            })
            .addCase(fetchChargingSessions.rejected, (state, action) => {
                state.chargingSessionList.isLoading = false
                state.chargingSessionList.error = action.error.message
            })
            .addCase(fetchChargingSessions.fulfilled, (state, action) => {
                state.chargingSessionList.isLoading = false
                state.chargingSessionList.error = false
                state.chargingSessionList.data = action.payload
            })

            .addCase(fetchActiveChargingSessions.pending, (state, action) => {
                state.activeChargingSessionList.isLoading = true
            })
            .addCase(fetchActiveChargingSessions.rejected, (state, action) => {
                state.activeChargingSessionList.isLoading = false
                state.activeChargingSessionList.error = action.error.message
            })
            .addCase(fetchActiveChargingSessions.fulfilled, (state, action) => {
                state.activeChargingSessionList.isLoading = false
                state.activeChargingSessionList.error = false
                state.activeChargingSessionList.data = action.payload
            })

            .addCase(fetchPaymentsList.pending, (state, action) => {
                state.paymentsList.isLoading = true
            })
            .addCase(fetchPaymentsList.rejected, (state, action) => {
                state.paymentsList.isLoading = false
                state.paymentsList.error = action.error.message
            })
            .addCase(fetchPaymentsList.fulfilled, (state, action) => {
                state.paymentsList.isLoading = false
                state.paymentsList.error = false
                state.paymentsList.data = action.payload
            })

            .addCase(chargeback.pending, (state, action) => {
                state.chargeback.isLoading = true
            })
            .addCase(chargeback.rejected, (state, action) => {
                state.chargeback.isLoading = false
                state.chargeback.error = action.error.message
            })
            .addCase(chargeback.fulfilled, (state, action) => {
                state.chargeback.isLoading = false
                state.chargeback.error = false
                state.chargeback.data = action.payload
            })
    },
})
export default transactionSlice.reducer
