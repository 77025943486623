import fetch from 'auth/FetchInterceptor'

const UserService = {}

UserService.getUserList = function (params) {
    return fetch({
        url: process.env.REACT_APP_API_URL+'portal/user',
        method: 'get',
        params,
    })
}

UserService.getUserListDynamic = function (params) {
    return fetch({
        url: process.env.REACT_APP_API_URL+`portal/user/GetUsersDynamic?index=${params.index}&size=10`,
        method: 'post',
        data: params.finalQuery,
    })
}

UserService.getUsersToExcel = function (data) {
    return fetch({
        url: process.env.REACT_APP_API_URL + 'portal/user/GetAllUsersExcel',
        method: 'post',
        data: data,
        responseType: 'blob',
    })
}

UserService.getUserDetail = function (params) {
    return fetch({
        url: process.env.REACT_APP_API_URL+`portal/user/${params}`,
        method: 'get',
        params,
    })
}

UserService.addUser = function (data) {
    return fetch({
        url: process.env.REACT_APP_API_URL+'portal/user',
        method: 'post',
        data: data,
    })
}

UserService.deleteUser = function(params) {
    return fetch({
        url: process.env.REACT_APP_API_URL+`portal/user/${encodeURIComponent(params)}`,
        method: 'delete',
        params,
    })
}

UserService.updateUser = function(data) {
    return fetch({
        url: process.env.REACT_APP_API_URL+'portal/user',
        method: 'put',
        data: data,
    })
}
export default UserService
