import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE, DIR_LTR, FELOCALE, BELOCALE } from 'constants/ThemeConstant'
import { env } from './EnvironmentConfig'

export const APP_NAME = 'EVC Portal'
export const APP_VERSION = 'v1.1.5'
export const API_BASE_URL = 'http://localhost'
export const APP_PREFIX_PATH = '/portal'
export const AUTH_PREFIX_PATH = '/auth'
export const REDIRECT_URL_KEY = 'redirect'
export const AUTHENTICATED_ENTRY = `${APP_PREFIX_PATH}/dashboards/analytics`
export const UNAUTHENTICATED_ENTRY = '/login'
const lang = localStorage.getItem(FELOCALE)
export const THEME_CONFIG = {
    navCollapsed: false,
    sideNavTheme: SIDE_NAV_LIGHT,
    locale: lang,
    navType: NAV_TYPE_SIDE,
    topNavColor: '#3e82f7',
    headerNavColor: '',
    mobileNav: false,
    currentTheme: 'light',
    direction: DIR_LTR,
    blankLayout: false,
    headerLanguage:'tr-TR'
}
