import { createSlice } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit'
import LogService from 'services/LogService'

export const initialState = {
    auditLogDynamicList: {
        data: [],
        isLoading: false,
        error: false,
    },

    diagnosticFilesList: {
        data: [],
        isLoading: false,
        error: false,
    },

    maintenanceLogs: {
        data: [],
        isLoading: false,
        error: false,
    },

    maintenanceLogsDynamic: {
        data: [],
        isLoading: false,
        error: false,
    },
}

export const fetchAuditLogsDynamic = createAsyncThunk('log/getAuditLogsDynamic', async (params, { rejectWithValue }) => {
    try {
        const response = await LogService.getAuditLogsDynamic(params)
        return response.data
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})

export const fetchDiagnosticFiles = createAsyncThunk('log/getDiagnosticFiles', async (data, { rejectWithValue }) => {
    try {
        const response = await LogService.getDiagnosticFiles(data)
        return [...response.data]
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})

export const fetchMaintenanceLogs = createAsyncThunk('log/getMaintenanceLogs', async (data, { rejectWithValue }) => {
    try {
        const response = await LogService.getMaintenanceLogs(data)
        return response.data
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})

export const fetchMaintenanceLogsDynamic = createAsyncThunk(
    'log/getMaintenanceLogsDynamic',
    async (params, { rejectWithValue }) => {
        try {
            const response = await LogService.getMaintenanceLogsDynamic(params)
            return response.data
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error')
        }
    }
)

export const fetchMaintenanceLogsExcel = createAsyncThunk(
    'log/getMaintenanceLogsExcel',
    async (data, { rejectWithValue }) => {
        try {
            const response = await LogService.getMaintenanceLogsExcel(data)
            return response
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error')
        }
    }
)

export const fetchAuditLogsExcel = createAsyncThunk(
    'log/getAuditLogsExcel',
    async (data, { rejectWithValue }) => {
        try {
            const response = await LogService.getAuditLogsExcel(data)
            return response
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error')
        }
    }
)

export const logSlice = createSlice({
    name: 'logs',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            // List Audit Logs
            .addCase(fetchAuditLogsDynamic.pending, (state, action) => {
                state.auditLogDynamicList.isLoading = true
            })
            .addCase(fetchAuditLogsDynamic.rejected, (state, action) => {
                state.auditLogDynamicList.isLoading = false
                state.auditLogDynamicList.error = action.error.message
            })
            .addCase(fetchAuditLogsDynamic.fulfilled, (state, action) => {
                state.auditLogDynamicList.isLoading = false
                state.auditLogDynamicList.error = false
                state.auditLogDynamicList.data = action.payload
            })

            // List Diagnostic Files
            .addCase(fetchDiagnosticFiles.pending, (state, action) => {
                state.diagnosticFilesList.isLoading = true
            })
            .addCase(fetchDiagnosticFiles.rejected, (state, action) => {
                state.diagnosticFilesList.isLoading = false
                state.diagnosticFilesList.error = action.error.message
            })
            .addCase(fetchDiagnosticFiles.fulfilled, (state, action) => {
                state.diagnosticFilesList.isLoading = false
                state.diagnosticFilesList.error = false
                state.diagnosticFilesList.data = action.payload
            })

            .addCase(fetchMaintenanceLogs.pending, (state, action) => {
                state.maintenanceLogs.isLoading = true
            })
            .addCase(fetchMaintenanceLogs.rejected, (state, action) => {
                state.maintenanceLogs.isLoading = false
                state.maintenanceLogs.error = action.error.message
            })
            .addCase(fetchMaintenanceLogs.fulfilled, (state, action) => {
                state.maintenanceLogs.isLoading = false
                state.maintenanceLogs.error = false
                state.maintenanceLogs.data = action.payload
            })

            .addCase(fetchMaintenanceLogsDynamic.pending, (state, action) => {
                state.maintenanceLogsDynamic.isLoading = true
            })
            .addCase(fetchMaintenanceLogsDynamic.rejected, (state, action) => {
                state.maintenanceLogsDynamic.isLoading = false
                state.maintenanceLogsDynamic.error = action.error.message
            })
            .addCase(fetchMaintenanceLogsDynamic.fulfilled, (state, action) => {
                state.maintenanceLogsDynamic.isLoading = false
                state.maintenanceLogsDynamic.error = false
                state.maintenanceLogsDynamic.data = action.payload
            })
    },
})
export default logSlice.reducer
