import fetch from 'auth/FetchInterceptor'
import { unarchiveRfid } from './../store/slices/driver/driverSlice';

const DriverService = {}

DriverService.getDriverList = function (params) {
    return fetch({
        url: process.env.REACT_APP_API_URL+'portal/driver',
        method: 'get',
        params,
    })
}

DriverService.getDriverListDynamic = function (params) {
    return fetch({
        url: process.env.REACT_APP_API_URL+`portal/Driver/GetDriversDynamic?index=${params.index}&size=10`,
        method: 'post',
        data: params.finalQuery,
    })
}

DriverService.getDriversToExcel = function (data) {
    return fetch({
        url: process.env.REACT_APP_API_URL + 'portal/Driver/GetDriversExcel',
        method: 'post',
        data: data,
        responseType: 'blob',
    })
}

DriverService.getDriverDetail = function (params) {
    return fetch({
        url: process.env.REACT_APP_API_URL+`portal/driver/${params}`,
        method: 'get',
        params,
    })
}

DriverService.addDriver = function (data) {
    return fetch({
        url: process.env.REACT_APP_API_URL+'portal/Driver',
        method: 'post',
        data: data,
    })
}

DriverService.updateDriver = function(data) {
    return fetch({
        url: process.env.REACT_APP_API_URL+'portal/driver',
        method: 'put',
        data: data,
    })
}

DriverService.updateAddress = function(data) {
    return fetch({
        url: process.env.REACT_APP_API_URL+'portal/driver/address',
        method: 'put',
        data: data,
    })
}

DriverService.updateVehicle = function(data) {
    return fetch({
        url: process.env.REACT_APP_API_URL+'portal/driver/vehicle',
        method: 'put',
        data: data,
    })
}


DriverService.getRfidList = function (params) {
    return fetch({
        url: process.env.REACT_APP_API_URL+`portal/driver/RFIDCards/${params}`,
        method: 'get',
        params,
    })
}

DriverService.getDriversByOperatorId = function (params) {
    return fetch({
        url: process.env.REACT_APP_API_URL+`portal/Operator/getDriversByOperatorId/${params}`,
        method: 'get',
        params,
    })
}

DriverService.addBalance = function (data) {
    return fetch({
        url: process.env.REACT_APP_API_URL+'portal/driver/balance',
        method: 'post',
        data: data
    })
}

DriverService.addAddresses = function (data) {
    return fetch({
        url: process.env.REACT_APP_API_URL+'portal/driver/addresses',
        method: 'post',
        data: data
    })
}

DriverService.addVehicles = function (data) {
    return fetch({
        url: process.env.REACT_APP_API_URL+'portal/driver/vehicles',
        method: 'post',
        data: data
    })
}

DriverService.addRfid = function (data) {
    return fetch({
        url: process.env.REACT_APP_API_URL+'portal/driver/RFIDCards',
        method: 'post',
        data: data
    })
}


DriverService.deleteDriver = function (params) {
    return fetch({
        url: process.env.REACT_APP_API_URL+`portal/driver/${params}`,
        method: 'delete',
        params
    })
}


DriverService.deleteRfid = function (params) {
    return fetch({
        url: process.env.REACT_APP_API_URL+`portal/driver/RFIDCards/${params}`,
        method: 'delete',
        params
    })
}

DriverService.unarchiveRfid = function (params) {
    return fetch({
        url: process.env.REACT_APP_API_URL+`portal/driver/RFIDCards/Unarchive/${params}`,
        method: 'put',
        params
    })
}



DriverService.changeStatus = function (data) {
    return fetch({
        url: process.env.REACT_APP_API_URL+'portal/driver/manageStatus',
        method: 'post',
        data: data
    })
}





export default DriverService
