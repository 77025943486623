import rootReducer from './rootReducer'
import { configureStore } from '@reduxjs/toolkit'

const middlewares = []

const store = configureStore({
    reducer: rootReducer(),
    
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            immutableCheck: false,
            serializableCheck: false,
        }).concat(middlewares),
    devTools: false,
    // devTools: process.env.NODE_ENV === 'dev',
    
})

store.asyncReducers = {}

export const injectReducer = (key, reducer) => {
    if (store.asyncReducers[key]) {
        return false
    }
    store.asyncReducers[key] = reducer
    store.replaceReducer(rootReducer(store.asyncReducers))
    return store
}

export default store
