import fetch from 'auth/FetchInterceptor'

const LogService = {}
// prettier-ignore
LogService.getAuditLogsDynamic = function (params) {
    // params : userId
    return fetch({
        url: process.env.REACT_APP_API_URL+`portal/AuditLogs/GetUserAuditLogsDynamic/${params.userId}?index=${params.index}&size=10`,
        method: 'post',
        data: params.finalQuery,
    })
}

LogService.getDiagnosticFiles = function (params) {
    // params : deviceId
    return fetch({
        url: process.env.REACT_APP_API_URL+'portal/Ftp/GetLogs',
        method: 'get',
        params: {
            chargePointId: params
        }
    })
}

LogService.getMaintenanceLogs = function (params) {
    return fetch({
        url: process.env.REACT_APP_API_URL+`messageTrace/MaintenanceLogs?ChargePointId=${params.chargePointId}&index=${params.index}&size=10`,
        method: 'get',
        params,
    })
}

LogService.getMaintenanceLogsDynamic = function (params) {
    return fetch({
        url: process.env.REACT_APP_API_URL+`messageTrace/MaintenanceLogs?index=${params.index}&size=10`,
        method: 'post',
        data: params.finalQuery,
    })
}

LogService.getMaintenanceLogsExcel = function (data) {
    return fetch({
        url: process.env.REACT_APP_API_URL + 'messageTrace/MaintenanceLogs/MaintenanceLogsExcel',
        method: 'post',
        data: data,
        responseType: 'blob',
    })
}

LogService.getAuditLogsExcel = function (params) {
    return fetch({
        url: process.env.REACT_APP_API_URL + `portal/AuditLogs/GetUserAuditLogsExcel?userId=${params.userId}`,
        method: 'post',
        data: params.finalQuery,
        responseType: 'blob',
    })
}




export default LogService
